// Import the Google Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

$tennis-bg-dark: #002f54;
$tennis-bg-light: #005381;
$tennis-bg-very-light: #5a9bbd;
$tennis-bg-very-light-gray: #bababa;
$tennis-color: #fff;
$tennis-ball-color: rgb(228, 226, 53);
$tennis-ball-color-dark: #aeac4c;

$input-disabled-bg: #999;

// override bootstrap variables
$body-bg: #fff;
$body-color: #000;
$link-color: $tennis-ball-color;
$link-hover-color: $tennis-ball-color-dark;
$font-family-base: "Poppins", sans-serif;
$enable-rounded: false;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: $font-family-base;
}

.tennis-primary,
.tennis-secondary {
  @extend .p-3;
  @extend .p-sm-5;
}

.tennis-header {
  background-image: url("./tennis-ball-logo.png");
  background-repeat: no-repeat;
  background-position: right 60%;
  background-size: 25%;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
  }
}

h2 {
  @extend .pb-3;
  text-transform: uppercase;
}

.tennis-primary {
  background-color: $tennis-bg-dark;
  color: $tennis-color;
}

.tennis-secondary {
  background-color: $tennis-bg-light;
  color: $tennis-color;
}

.startButton {
  background-color: $tennis-ball-color !important;
  &:hover {
    background-color: $tennis-ball-color-dark !important;
  }
}

.tennis-ball-color {
  color: $tennis-ball-color;
}

.tennis-odds .row {
  @extend .py-2;
  &.table-header {
    font-weight: bold;
  }
  .form-label {
    @extend .pt-2;
  }
  .slider-selection-info {
    margin-top: 8px;
  }
}

.horizontal-slider {
  .slider-track {
    height: 8px;
    top: 16px;
    background-color: rgba(255, 255, 255, 0.1);
    &.slider-track-0 {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .slider-mark {
    width: 12px;
    height: 12px;
    top: 14px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    margin-left: 14px;
  }
  .tennis-ball-thumb {
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    text-align: center;
    height: 40px;
    width: 40px;
    line-height: 40px;
    cursor: ew-resize;
    font-size: 12px;
    user-select: none;
    &.active {
      box-shadow: 0 0 5px $tennis-ball-color;
    }
  }
  &.disabled {
    .slider-track {
      background-color: #000;
    }
    .tennis-ball-thumb {
      background-color: $input-disabled-bg;
      cursor: not-allowed;
    }
  }
}

table.single-match-result.in-progress
  tr.last-point-winner
  td.points-won-in-game {
  background-color: $tennis-ball-color;
}

.tennis-stats table {
  th {
    background-color: $tennis-bg-light;
    color: #fff;
  }

  tr.divider-row td {
    font-weight: bold;
  }

  td {
    position: relative;
    text-wrap: nowrap;

    div.indicator {
      background-color: $tennis-bg-very-light-gray;
      position: absolute;
      bottom: 0;
      height: 3px;
      &.has-more {
        background-color: $tennis-bg-very-light;
      }
    }

    &:nth-child(2) div.indicator {
      right: 0;
    }

    &:nth-child(3) div.indicator {
      left: 0;
    }
  }
}

.tennis-ball-spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  position: relative;
  top: 4px;
  background-image: url("./tennis-ball-spinner.svg");
  background-size: cover;
  animation: spin 1s linear infinite;
}

.form-select:disabled,
.form-control:disabled {
  background-color: $input-disabled-bg;
  cursor: not-allowed;
}

.form-check-input:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  body {
    font-size: 12px !important; // Font size for small screens (up to 768px)
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .tennis-stats table {
    font-size: 12px;
  }
}

// Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap";
